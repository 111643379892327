/* #main {
    padding-top: 69px;
    padding-left: 235px;
} */

.jumbo {
    border-radius: 0 !important;
    background-repeat: no-repeat !important;
    /* height: 500px !important; */
    background-size: cover !important;
    color: #00204A !important;
    font-weight: bold !important;
    /* text-shadow: 2px 2px 4px #191919; */
}