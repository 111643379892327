.uploadContainer {
    position: relative;
}

.uploadArea {
    position: relative;
    display: 'block';
    text-align: center;
    padding: '20px';
    border: .75rem dashed #a1d6fb;
    border-radius: 5px;
    background-color: '#fafafa';
    color: '#bdbdbd';
    outline: 'none';
    transition: 'border .24s ease-in-out';
    padding: 4rem;
    cursor: pointer;
    outline: none;
    max-width: 35rem;
    margin: 3rem auto;
}

.uploadArea .title {
    color: #85c8f8;
    font-size: 2rem;
    line-height: 2rem;
    font-weight:  bold;
}

.uploadArea .subtext {
    color: #85c8f8;
    margin-top: .5rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
}

.uploadArea .superscript {
    color: #d67900;
    font-weight: bold;
    margin-top: .6rem;
    line-height: .75rem;
    font-size: .75rem;
}

:hover.uploadArea {
    border-color: #85c8f8;
    background-color: #f1f9ff;
}

:hover.uploadArea .title {
    color: #65bdfc;
}

:hover.uploadArea .subtext {
    color: #65bdfc;
}

:hover.uploadArea .superscript {
    color: #d67900;
}

.uploadArea.accept {
    border-color: #a5cea7;
    background-color: #ecf5ef;
}

.uploadArea.reject {
    border-color: #ce8282;
    background-color: #f5f1f1;
}

.uploadArea .alert {
    position: absolute;
    top: -.75rem;
    left: -.75rem;
    right: -.75rem;
    bottom: -.75rem;
    overflow: none;
    border: .75rem solid transparent;
    border-radius: 5px;
    outline: none;
    margin: 0;
    text-align: center;
    transition: opacity .75s ease-out, background-color .75s ease-out, border-color .75s ease-out;
}

.uploadArea .alert.inactive {
    background-color: transparent;
    opacity: 0;
}

.uploadArea .alert.success {
    background-color:  #37773b;
    border-color: #37773b;
    opacity: 1;
}

.uploadArea .alert.fail {
    background-color:  #841f1f;
    border-color: #841f1f;
    opacity: 1;
}

.uploadMsgContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
}

.uploadMsgContainer > div {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.uploadMsg {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 5rem;
}

.uploadMsg > div:nth-child(1) {
    margin-right: 14px;
    padding-top: 5px;
}
  
.uploadMsg > div:nth-child(2) {
    flex-grow: auto;
    width: 100%;
    text-align: left;
}
  
div.uploadMsg strong.title, :hover.uploadArea div.uploadMsg strong.title {
    display: block;
    color: #fff;
    margin-top: 0rem;
    font-size: 2rem;
    line-height: 2rem;
}

div.uploadMsg div.content {
    color: #fff;
    padding-top: 0.3rem;
    font-size: 1rem;
    line-height: 1rem;
    white-space: nowrap;
}