.custom-icon-append, .custom-icon-prepend {
    background-color: #2196f3 !important;
    border-color: #2196f3 !important;
}

.custom-icon-append {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.custom-icon-prepend {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

/* fixes discrepincies between widths of various font-awesome icons */
.custom-icon-append > svg {
    min-width: 14px;
}

.custom-icon-prepend.money {
    background-color: #679c58 !important;
    border-color: #679c58 !important;
}

.input-group > input:disabled ~ .input-group-append .custom-icon-append {
    background-color: #ced4da !important;
    border-color: #ced4da !important;
}

.input-group.has-error > input.form-control {
    outline: 1px solid red;
}

.input-group.no-error > .custom-warning-box {
    display: none;
}