.indices__item img {
  max-width: 300px;
}

.indices__title {
  font-size: 42px;
  margin-bottom: 50px;
  font-weight: 300;
}

.indices__title span {
  font-weight: bold;
}
