.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.full-height {
  min-height: 100vh;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.account-wall {
  padding: 40px 0px 20px 0px;
  background-color: #ffffff;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login-title {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  display: block;
}

.profile-img {
  width: 70%;
  margin: 0 auto 10px;
  display: block;
  margin-bottom: 50px;
}

.new-account {
  display: block;
  margin-top: 10px;
}
