.item-label{
    font-weight: bold;
}

.underlined {
    border-style: none none inset none;
    border-color: lightgray;
    border-width: .5px;
}

.person-display-container {
    padding-left:24px; 
    padding-right:14px; 
    margin-top: 5px;
    padding-top:24px;
    background-color: whitesmoke;
}