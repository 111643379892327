.productText {
    padding: 0 50px 50px 50px !important;
    font-size: 18px !important;
}

Button a {
    color: white !important;
}

.breadcrumb {
    margin: 20px 0px 20px 0;
}

article {
    max-width: 35em;
    text-align: justify;
    line-height: 1.7em;
}