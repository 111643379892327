.not-allowed {
  cursor: not-allowed !important;
}

/*** Date Picker ***/

.datePickerGroup {
  width: 315px !important;
}

.date-start,
.date-end {
  margin-bottom: 0px;
  width: 130px;
  display: inline-block;
}

.date-start input {
  margin-right: -70px !important;
  cursor: pointer;
}

.date-end input {
  margin-right: -70px !important;
  cursor: pointer;
}

.date-start label,
.date-end label {
  position: relative;
  left: -25px;
  margin-right: -15px;
  cursor: pointer;
}

/*** Modal Popup ***/
.modal-content {
  padding: 1.5rem 2.5rem;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.4);
}

.modal-body>div,
.modal-header>h5 {
  white-space: pre-line;
  margin: 0.5rem 0;
}

.modal-header h1 {
  color: #337ab7;
}

.modal-footer>div {}



.row {
  margin: 0;
}

.chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.chat img {
  width: 200px;
}

button.MuiButton-root.Mui-disabled {
  background: rgba(0, 0, 0, 0.12) !important;
}