.tableHeader {
  background-color: rgba(0, 0, 0, 0.03);
}
/* background-color: rgba(0,0,0,.03) */

.notAuthorized {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  color: #00aabb;
  font-weight: bold;
  font-size: 3vw;
  white-space: nowrap;
}
