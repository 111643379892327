table {
  font-size: 14px;
}

.row {
  margin-bottom: 20px;
}

.illustration__table * {
  padding: 10px !important;
  font-size: 0.8rem !important;
}

.table-title {
  font-size: 1.25rem !important;
}
