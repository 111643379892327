body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.activeNavLink {
  font-weight: bold;
  font-size: 18px;
  list-style-type: none;
  color: #ffffff;
}

a.navLink {
  font-size: 18px;
  list-style-type: none;
  color: #ffffff;
}

a,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

.leftNavContainer {
  padding: 20px;
  position: absolute;
  width: 180px;
  background-color: #f15b2a;
  display: block;
  height: 100%;
}

.leftNavNested {
  padding: 20px;
  height: 100%;
  width: 200px;
  background-color: #000000;
}

.rightContentContainer {
  margin-left: 200px;
  width: 80%;
  display: contents;
}

li.nav {
  list-style-type: none;
  margin-left: -30px;
}

.header {
  background-color: #000000;
  height: 90px;
}

.header-logo {
  margin-top: -25px;
  margin-left: 30px;
  width: 75px;
  display: inline-block;
  text-align: left;
}

.header-text {
  padding-top: 20px;
  color: #ffffff;
  font-size: 40px;
  display: inline-block;
  margin-left: 80px;
}

.unicorn {
  padding-left: 0px;
  margin-left: 12px;
  width: 80px;
  margin-top: 20px;
}

.a-disabled {
  color: gray;
  pointer-events: none;
}
