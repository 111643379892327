.MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
}

.notes, .action-date, .withdrawal-adjustment-details {
    padding-bottom: 20px;
}

.details-top-section, .details-bottom-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.mva-adjustment {
    padding-bottom: 20px;
}

.new-total-charges-amount, .cash-surrender-clause {
    padding-top: 20px;
}

.symbol {
    width: 20px;
    display: inline-block;
}
