.support-tabs {
    color: #495057 !important;
    cursor: pointer;
}

.embed-container {
position: relative;
padding-bottom: 56.25%;
overflow: hidden;
}
        
.embed-container iframe,
.embed-container object,
.embed-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}