.sales-tools__item img {
  height: 150px;
  width: auto;
  margin: auto;
}

.sales-tools__title {
  font-size: 42px;
  margin-bottom: 50px;
  font-weight: 300;
}

.sales-tools__title span {
  font-weight: bold;
}
