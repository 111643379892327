.rider-card-information-section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    margin: 0;
    padding: 0;
    gap: 0.5em;

    /* default stacked list styling (with NO css_class_override sent from api) */
    > .rider-information-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        margin: 0;
        padding: 0;
        gap: 1em;

        /* collapsable box */
        > div {
            display: flex;
            width: fit-content;
            flex-direction: row;
            flex-wrap: nowrap;
            flex-basis: auto;
            width: 100%;
            gap: .5em;

          > div { 
            width: fit-content;
            display: flex;
            flex-basis: auto;
            gap: .5em;
            
            &.rider-sub-header-row {
                > div {
                    font-weight: 500;
                    white-space: nowrap;
                    &::after {
                        content: ':'
                    }
                }
            }
          }
        }
    }

    /* default styling if display is set to be columns rather than stacked sections*/
    &.rider-list-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 1.5em;
        column-gap: 0.5em;

        > .rider-information-column {
            flex-direction: column;
            width: 100%;
            min-width: min-content;
            flex-wrap: wrap;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            gap: .5em;

            /* collapsable box */
            > div {
              display: flex;
              flex-direction: column;
              gap: .5em;

              /* base grid template */
              > div { 
                width: 100%;
                display: grid !important;
                grid-template-columns: repeat(1, 1fr);
                gap: .5em;

                > div {
                    white-space: nowrap; /* i put this in here so we could always know the height of the grid as it will not wrap, but we may need to remove in the future if they add cells with more content that we want to wrap */
                }

                /* we dont want the colon to display on grid column headers */
                &.rider-sub-header-row > div::after {
                    content: none;
                }
            }
          }

          /* 2 column grid cell template */
          > div > div.data-grid-columns-2 {
              grid-template-columns: repeat(2, 1fr);
          }

          /* 3 column grid cell template */
          > div > div.data-grid-columns-3 {
              grid-template-columns: repeat(3, 1fr);
          }

          /* 4 column grid cell template */
          > div > div.data-grid-columns-4 {
              grid-template-columns: repeat(4, 1fr);
          }
        }
    }

    /* three column layout list styling */
    &.three-column-layout > .rider-information-column {
        flex-basis: auto;
        width: min-content;

        &.use-one-column {
            width: calc(33% - 0.5em);
            flex-grow: 1;
        }
        &.use-two-column {
            width: calc(66% - 0.5em);
            flex-grow: 2;
        }
    }

    /* two column layout list styling */
    &.two-column-layout > .rider-information-column {
        width: calc(50% - 0.5em);
        flex-basis: calc(50% - 0.5em);
    }
}


.collapsible {
    > label {
        text-wrap: nowrap;
        display: block;
        cursor: pointer;
        color: #007bff;
    
        &:hover {
            color: #0366d0
        }
    
        > input {
            visibility: hidden;
            height: 0;
            width: 0;
            margin: 0;
        }
    }
  
    &:has(> label > input:checked) {
        &>label::after {
            content: 'Show Less';
        }
        > div {
            max-height: fit-content;
        }
    }

    &:not(:has(> label > input:checked)) {
        &>label::after {
            content: 'Show More';
        }
    }

    > div { 
        max-height: 22em;
        overflow: hidden;
        transition: max-height .5s;
        transition-timing-function: ease-in;
    }
}
.status.available {
    color: green;
    font-weight: 500;
}
.status.active {
    color: blue;
    font-weight: 500;
}