.sidebar {
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 250px;
  width: 250px;
  position: fixed;
  border-radius: 0 !important;
}

@media screen and (max-width: 900px) {
  .sidebar {
    position: relative;
    height: 100vh;


  }
}

.sidebar::-webkit-scrollbar {
  width: 10px;
}

.link {
  background-color: inherit;
  color: inherit;
  letter-spacing: 0px;
  border-radius: 16px;
}

.link svg {
  margin-right: 8px;
}

.profile-footer-option {
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 1.25px;
  color: #196284;
  text-transform: uppercase;
  opacity: 1;
  max-width: 200px;
}

.profile-link {
  text-align: center;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 950px;
}

.content-offset {
  margin-left: 250px;
}

@media screen and (max-width: 900px) {
  .content-offset {
    margin-left: 0;
  }
}