.single-value-container {
  padding: 10px;
  height: 100%;
}

.single-value-container h2 {
  margin-left: 10px;
}

.single-value-container h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-value-container .paper-display-footer {
  display: flex;
  justify-content: flex-end;
  font-size: smaller;
  width: 100%;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
}

.underlined {
  border-bottom: 1px blue !important;
}

.data-label {
  font-weight: 700;
}

.data-value {
  display: flex;
  justify-content: center;
}

.card-calculation-footer {
  margin-bottom: 30px;
  margin-right: 10px;
}

.account-value__cash-surrender-value-container {
  padding: 10px 10px 30px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.link-button {
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover {
  color: #0056B3;
}

.link-button:visited { 
  color: #551A8B;
}
