#wrapper {
  display: flex;
}

#sidebar {
  height: 100vh;
  width: 218px;
  position: fixed;
  top: 70px;
}

#sidebar.header {
  text-align: center;
}

.divider {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 170px;
  margin: 0 auto;
}

.userArea {
  padding: 16px 0;
  max-width: 160px;
  margin: 0 auto;
}

.userPicture {
  margin: 0 auto;
  border-radius: 100%;
  max-width: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 2px solid lightblue;
  -webkit-box-shadow: 0px 1px 10px 0px rgba(43, 52, 70, 0.3);
  -moz-box-shadow: 0px 1px 10px 0px rgba(43, 52, 70, 0.3);
  box-shadow: 0px 1px 10px 0px rgba(43, 52, 70, 0.3);
}

.userPicture ::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.userPicture img {
  height: 60px;
  width: 100%;
}

.userName {
  color: white;
  font-size: 0.88em;
  text-align: center;
  margin-top: 12px;
  font-weight: 400;
  line-height: 1.3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ul.navigation {
  list-style-type: none;
  margin-top: 22px;
  padding: 0;
}

.divider {
  margin: 12px auto;
}

#sidebar ul.navigation li .link {
  font-size: 1em;
  padding: 11px 24px;
  color: white;
  display: block;
  border-left: 3px solid transparent;
  text-decoration: none;
  position: relative;
}

#sidebar ul.navigation li .link-disabled {
  font-size: 1em;
  padding: 11px 24px;
  color: #777777 !important;
  background-color: #e9ecef;
  display: block;
  border-left: 3px solid transparent;
  text-decoration: none;
  position: relative;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

#sidebar ul.navigation li .link:not(.active):hover {
  background-color: rgba(0, 32, 74, 0.2);
}

#sidebar .link.active {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #fff;
  font-weight: bold;
  color: #0072c0 !important;
}

#sidebar .link.active svg path {
  color: #0072c0;
}

#sidebar .link svg {
  margin-right: 0.5em;
}

#sidebar {
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}

@media screen and (max-width: 900px) {
  #sidebar {
    z-index: 500;
    width: 100%;
    height: 0%;
    overflow: hidden;
  }

  #sidebar .link.active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  #sidebar .navigation li {
    border-bottom: 1px white solid;
  }

  .divider {
    display: none;
  }

  #sidebar.menuVisable {
    width: 100%;
    height: 100%;
  }
}

.sidebar-offset {
  margin-left: 250px;
}

@media screen and (max-width: 900px) {
  .sidebar-offset {
    margin-left: 0;
  }
}