#mainContent {
    padding-top: 69px;
    padding-left: 235px;
}

.note-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
}

.note-container > div {
    flex-grow: 1;
}

.note-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.note-method {
    margin: 0 0 0 5px;
    width: 16px;
}

.note-note {
    margin: 10px 10px;
    color: #777;
    white-space: pre-wrap;
}

.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: rgb(135, 200, 243);
}

.bs-tooltip-right {
    margin-left: .5rem;
}
  
.tooltipBlue {
    background-color: rgb(135, 200, 243);
    color: rgb(255, 255, 255);
    box-shadow: .2rem .2rem .1rem rgba(0, 0, 0, 0.116);
    min-width: 23rem;
    text-align: left;
    line-height: 1rem;
    padding: .5rem;
}

.tooltipBlue h6 {
    line-height: 1rem;
    margin-bottom: .3rem;
}

.piechart-tooltip {
    color: white;
    box-shadow: .2rem .2rem .1rem rgba(0, 0, 0, 0.116);
    text-align: center;
    font-size: 1.2em;
    line-height: 1rem;
    padding: .5rem;
    position: absolute;
}
