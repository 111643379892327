.custom-icon-append {
    background-color: #97c0e0;
    border-color: #97c0e0;
}

/* fixes discrepincies between widths of various font-awesome icons */
.custom-icon-append > svg {
    min-width: 14px;
}

.custom-icon-append.money {
    background-color: #aedea0;
    border-color: #aedea0;
}

.input-group > input:disabled ~ .input-group-append .custom-icon-append, 
.input-group > input:read-only ~ .input-group-append .custom-icon-append,
.input-group > input:disabled ~ .input-group-preppend .custom-icon-append, 
.input-group > input:read-only ~ .input-group-prepend .custom-icon-append {
    background-color: #ced4da !important;
    border-color: #ced4da !important;
}

/* This implementation with the .flex-reverse at the .input-group level, fixes a shortcoming in bootstraps implementation where 
we cannot style a previous sibling based on the readonly/disabled attribute of the input. 
This relies on the html input being placed in the code 
before the append/prepend div. CSS cannot find a previous sibling. So we are flipping the displayed order, but can still access and
display the prepend based on input state */

.flex-reverse {
    flex-direction: row-reverse;
}

.input-group.flex-reverse > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.input-group.flex-reverse > .input-group-prepend > .custom-icon-append {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

