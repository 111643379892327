fieldset.scheduler-border {
    border: 1px groove #ced4da !important;
    border-radius: 3px;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #dfdfdf;
            box-shadow:  0px 0px 0px 0px #dfdfdf;
    border-color:rgba(0,0,0,.125) !important;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    text-align: left !important;
    width:auto;
    padding:10px;
    border: 1px groove #ced4da;
    color: #00204A;
    border-color:rgba(0,0,0,.125) !important;
    border-radius: 3px;
}

.info-text {
    font-size: .9em !important;
}

.sup {
    font-size: .8em !important;
}

.info-box {
    display: flex;
}

.info-box > b {
    width: 4em;
}

.info-box > b.col-three {
    width: 9em;
}

.info-box > div {
    flex-grow: 1;
}
