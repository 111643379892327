.jumbo {
    border-radius: 0 !important;
    background-repeat: no-repeat !important;
    /* height: 500px !important; */
    background-size: cover !important;
    color: #00204A !important;
    font-weight: bold !important;
    /* text-shadow: 2px 2px 4px #191919; */
}

.textCenter {
    text-align: center !important;
    
}

.dashboardCardtitle {
    font-size: 300%;
    color: #00204A;
    font-weight: bold;
}

.muted-text {
    color: #6c757d !important;
}

.textBackgroundDiv {
    position: relative;
    font-weight: bold !important;
}

.innerBox {
  background:rgba(255,255,255,0.5);
  max-width: 70%;
  padding:5px 5px 5px 25px;
}
