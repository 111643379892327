.navbar {
  -webkit-box-shadow: 0 6px 15px -6px #323232;
  -moz-box-shadow: 0 6px 15px -6px #323232;
  box-shadow: 0 6px 15px -6px #323232;
  z-index: 1;
  color: white;
  position: fixed;
  height: 70px;

  /* the rest of your styling */
}

#header a {
  color: white !important;
}

#header .navItem a,
#header a.navItem {
  color: black !important;
}

#bodyOffset {
  height: 70px;
}

.headerOptionMenu {
  position: absolute !important;
  margin: 8px -18px 0 0 !important;
  border-radius: 0 0 0 10px !important;
  border-color: transparent !important;
  -webkit-box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.25);
  box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.headerOptionMenu.show {
  /*max-height: 300px !important;*/
}

.headerMenuBtn {
  display: none;
}

.headerOptions {
  display: inline-block;
}

#header nav {
  display: flex;
  flex-flow: row nowrap;
}

.headerLogo img {
  height: 40px;

}

@media screen and (max-width: 900px) {
  .headerMenuBtn {
    color: #fff;
    display: block !important;
  }

  .headerBrand {
    text-align: center;
    display: block;
    position: relative;
  }



  .headerOptions {
    display: block;
    position: relative;
  }
}

@media screen and (max-width: 600px) {
  .headerLogo img {
    height: 30px;

  }
}